<template>
  <main-layout>
    <NuxtPage />
  </main-layout>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router';
import MainLayout from '~/components/layout/main-layout.vue';

const route = useRoute();
const runtimeConfig = useRuntimeConfig();

useHead({
  title: () => route.meta.title ? `${route.meta.title}` : 'Sales Area - автоматические ответы на отзывы и аналитика продаж для Wildberries',
  meta: [
    { name: 'description', content: () => route.meta.description ? `${route.meta.description}` : `${route.meta.title} | Sales Area - автоматические ответы на отзывы и аналитика продаж для Wildberries`},
    { name: 'keywords', content: route.meta.keywords ? `${route.meta.keywords}` : 'sales area, автоматические ответы на отзывы wildberries, ответы на отзывы без текста, уведомления о негативных отзывах, безопасный сервис работы с wildberries, логистика и распределение поставок по складам, CRM для маркетплейсов, сервис аналитики продаж wildberries'},
    { property: 'og:locale', content: 'ru_RU'},
    { property: 'og:type', content: 'website'},
    { property: 'og:title', content: () => route.meta.title ? `${route.meta.title}` : 'Sales Area - автоматические ответы на отзывы и аналитика продаж для Wildberries'},
    { property: 'og:description', content: () => route.meta.description ? `${route.meta.description}` : `${route.meta.title} | Sales Area - автоматические ответы на отзывы и аналитика продаж для Wildberries`},
  ],
  link: [
    {
      rel: 'canonical',
      href: 'https://sales-area.ru' + route.path,
    },
  ],
  ...(runtimeConfig?.public?.nuxtMode !== 'development' ? {
    script: [
      {
        innerHTML: `var _tmr = window._tmr || (window._tmr = []);
                  _tmr.push({id: "3505330", type: "pageView", start: (new Date()).getTime()});
                  (function (d, w, id) {
                    if (d.getElementById(id)) return;
                    var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                    ts.src = "https://top-fwz1.mail.ru/js/code.js";
                    var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                    if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                  })(document, window, "tmr-code");`,
      },
    ],
    noscript: [
      {
        innerHTML: `<div>
                    <img src="https://top-fwz1.mail.ru/counter?id=3505330;js=na"
                      style="position:absolute;left:-9999px;"
                      alt="Top.Mail.Ru"
                    />
                  </div>`,
      },
    ],
  } : {}),
});
</script>