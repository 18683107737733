<template>
  <div class="content">
    <template v-if="!state.loading">
      <main-header v-if="state.screenWidth > 915" />
      <main-header-mobile v-else />
      <main class="main">
        <slot />
      </main>
      <main-footer class="footer" />
    </template>
    <span
      v-else
      class="app-loader"
    />
  </div>
</template>

<script setup lang="ts">
import MainHeader from '~/components/layout/main-header.vue';
import MainFooter from '~/components/layout/main-footer.vue';
import MainHeaderMobile from '~/components/layout/main-header-mobile.vue';

const state = reactive({
  screenWidth: 701,
  loading: true,
});
const getScreenWidth = () => {
  state.loading = true;
  state.screenWidth = document.body.offsetWidth;
  state.loading = false;
};
onMounted(() => {
  getScreenWidth();
  window.addEventListener('resize', getScreenWidth);
});
onUnmounted(() => {
  window.removeEventListener('resize', getScreenWidth);
});
</script>


<style lang="scss">
.app-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  border: 5px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
}
.main {
  flex: 1 1 auto;
}
.footer {
  flex: 0 0 auto;
}
</style>
