import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_CICqBBANNd from "/app/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_bKSoGl1L7p from "/app/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_LcKgStRyi6 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_nuxt3_gGdllsjxuZ from "/app/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import google_recaptcha_rYK9Kziv68 from "/app/plugins/google-recaptcha.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  strapi_CICqBBANNd,
  plugin_bKSoGl1L7p,
  i18n_yfWm7jX06p,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  plugin_nuxt3_gGdllsjxuZ,
  google_recaptcha_rYK9Kziv68,
  vue_final_modal_pML93k5qcp
]