<template>
  <div class="mobile-nested-link__container">
    <button
      class="mobile-nested-link__button"
      :class="{'opened': state.isLinkOpened}"
      @click="state.isLinkOpened = !state.isLinkOpened"
    >
      <span>{{ $t('links.services') }}</span>
    </button>
    <div
      v-show="state.isLinkOpened"
      class="mobile-nested-link__links"
    >
      <nuxt-link
        v-for="(link, index) in links"
        :key="index"
        class="mobile-nested-link__links-item"
        :to="link.to"
        @click="emits('reset-overflow')"
      >
        {{ link.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  label: string;
  links: {
    to: string,
    label: string,
  }[]
}
interface IEmits {
  (event: 'reset-overflow'): void;
}

defineProps<IProps>();
const emits = defineEmits<IEmits>();
const state = reactive({
  isLinkOpened: false,
});
</script>

<style scoped lang="scss">
.mobile-nested-link {
  &__container {

  }
  &__button {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: 28px;
    font-weight: 900;
    color: white;

    &:after {
      content:'';
      position: absolute;
      width: 36px;
      height: 36px;
      top: 0;
      right: 0;
      background: url('/svg/dropdown.svg') no-repeat;
    }
    &.opened {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  &__links {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 32px;
    &-item {
      color: white;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
