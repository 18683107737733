<template>
  <div
    class="nested-link__container"
    :class="{'hovered': state.isDropdownHovered || state.isActive}"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  >
    <span class="nested-link__label">
      {{ label }}
    </span>
    <div
      v-if="state.isActive"
      class="nested-link__dropdown"
      @mouseover="dropdownOver"
      @mouseleave="dropdownLeave"
    >
      <nuxt-link
        v-for="(link, index) in links"
        :key="index"
        class="nested-link__link"
        :to="link.to"
        @click="() => {
          state.isActive = false
          state.isDropdownHovered = false
        }"
      >
        {{ link.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  label: string;
  links: {
    to: string,
    label: string,
  }[]
}
interface IState {
  isActive: boolean;
  isDropdownHovered: boolean;
}
defineProps<IProps>();
const state = reactive<IState>({
  isActive: false,
  isDropdownHovered: false,
});
const mouseOver = () => {
  state.isActive = true;
};
const mouseLeave = () => {
  setTimeout(() => {
    if (!state.isDropdownHovered) {
      state.isActive = false;
    }
  }, 500);
};
const dropdownOver = () => {
  state.isDropdownHovered = true;
};
const dropdownLeave = () => {
  state.isDropdownHovered = false;
  mouseLeave();
};
</script>

<style scoped lang="scss">
.nested-link {
  &__container {
    position: relative;

    &.hovered {
      color: var(--accent-dark);
      border-radius: 8px;
      background: linear-gradient(107.3deg, #D9F4EF 26.53%, #D5E3FF 79.42%);

      &::after {
        position: absolute;
        content: '';
        background: linear-gradient(105.51deg, var(--blue) 35.41%, var(--lime) 117.32%);
      }
    }
  }

  &__label {
    position: relative;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: linear-gradient(107.3deg, #D9F4EF 26.53%, #D5E3FF 79.42%);
    animation: 0.2s dropdown ease;
    min-width: 270px;
    z-index: 1;
    padding: 8px;
    top: calc(100% + 8px);
    left: 0;
    transition: opacity;
  }

  &__dropdown {
    top: calc(100% + 20px);
    left: 0;
    gap: 16px;
    padding: 20px 40px 20px 20px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__link {
    font-size: 20px;
    font-weight: 600;
  }

  @keyframes dropdown {
    0% {
     height: 0;
     opacity: 0;
    }

    to {
     height: 208px;
     opacity: 1;
    }
  }

  &__link {
    color: var(--accent-dark);

    &:hover {
      background-image: linear-gradient(105.51deg, var(--blue) 35.41%, var(--lime) 117.32%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      background-size: 100%;
      background-repeat: repeat;
    }
  }
}

@media (min-width: 1024px) {
  .nested-link {
    &__container {
      &.hovered {
        border-radius: 8px;

        &::after {
          left: 0;
          bottom: -15px;
          height: 3px;
          width: 100%;
        }
      }

      &__label {
        padding: 12px 18px;
      }
    }

    // &__dropdown {
    //   top: calc(100% + 20px);
    //   left: 0;
    //   gap: 16px;
    //   padding: 20px 40px 20px 20px;
    //   border-radius: 8px;
    // }

    // &__link {
    //   font-size: 20px;
    //   font-weight: 600;
    // }
  }
}
</style>
