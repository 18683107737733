<template>
  <footer class="footer">
    <h4 class="footer__address">
      ООО "Смартлинк" ИНН 7813654235 ОГРН 1217800091134
    </h4>
    <div class="footer__links">
      <nuxt-link
        class="footer__links-item"
        to="privacy"
        target="_blank"
      >
        {{ $t('confidencePolicy') }}
      </nuxt-link>
      <nuxt-link
        class="footer__links-item"
        to="licensing"
        target="_blank"
      >
        {{ $t('userAgreement') }}
      </nuxt-link>
    </div>
    <h4 class="footer__captcha">
      This site is protected by reCAPTCHA and the Google
      <a
        class="footer__link"
        target="_blank"
        href="https://policies.google.com/privacy"
      >
        Privacy Policy
      </a> and
      <a
        class="footer__link"
        target="_blank"
        href="https://policies.google.com/terms"
      >
        Terms of Service
      </a> apply.
    </h4>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: var(--footer-bg-color);
  font-size: 16px;
  font-weight: 500;
  color: rgba(242, 247, 253, 1);
  &__link {
    color: white;
    font-weight: 900;
  }

  &__links {
    display: flex;
    gap: 16px;
    color: inherit;
    font-weight: 900;

    &-item {
      color: inherit;
      font-weight: inherit;
      line-height: 28px;
    }
  }

  &__captcha {
    color: inherit;
    font-weight: inherit;
    line-height: 28px;
  }
}

@media (max-width: 500px) {
  .footer {
    padding: 35px 50px;
    gap: 16px;

    &__links {
      flex-direction: column;
      gap: 0;
    }

    &__captcha {
      text-align: center;
    }

    &__address {
      text-align: center;
    }
  }
}
</style>
