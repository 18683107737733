import { default as indexzPMUqgKHzSMeta } from "/app/pages/advertisement/index.vue?macro=true";
import { default as indexODSzlMCpckMeta } from "/app/pages/agreement/index.vue?macro=true";
import { default as indexOjY3oAgQCdMeta } from "/app/pages/ai-review-response-generator/index.vue?macro=true";
import { default as index3VG1nzc0GsMeta } from "/app/pages/analytics/index.vue?macro=true";
import { default as indexSIVPhayj0XMeta } from "/app/pages/autoanswers/index.vue?macro=true";
import { default as _91_46_46_46slug_93jK3Pyj8dSOMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as indextaC1gSU56TMeta } from "/app/pages/crm/index.vue?macro=true";
import { default as indexthqyUOyVUjMeta } from "/app/pages/forgot-pass/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index3gq6Cp6LyXMeta } from "/app/pages/licensing/index.vue?macro=true";
import { default as _91_46_46_46slug_93Cy8OQ3llwUMeta } from "/app/pages/news/[...slug].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as indexCICQ7TchH0Meta } from "/app/pages/order/index.vue?macro=true";
import { default as indexNwMsGxInsLMeta } from "/app/pages/prices/index.vue?macro=true";
import { default as indextCDe67vQCCMeta } from "/app/pages/privacy/index.vue?macro=true";
import { default as indexKgLIvXGBWxMeta } from "/app/pages/signin/index.vue?macro=true";
import { default as indexxLsScIFze3Meta } from "/app/pages/signup/index.vue?macro=true";
import { default as indexF5mW9etUSbMeta } from "/app/pages/telegram-bot/index.vue?macro=true";
export default [
  {
    name: indexzPMUqgKHzSMeta?.name ?? "advertisement",
    path: indexzPMUqgKHzSMeta?.path ?? "/advertisement",
    meta: indexzPMUqgKHzSMeta || {},
    alias: indexzPMUqgKHzSMeta?.alias || [],
    redirect: indexzPMUqgKHzSMeta?.redirect,
    component: () => import("/app/pages/advertisement/index.vue").then(m => m.default || m)
  },
  {
    name: indexODSzlMCpckMeta?.name ?? "agreement",
    path: indexODSzlMCpckMeta?.path ?? "/agreement",
    meta: indexODSzlMCpckMeta || {},
    alias: indexODSzlMCpckMeta?.alias || [],
    redirect: indexODSzlMCpckMeta?.redirect,
    component: () => import("/app/pages/agreement/index.vue").then(m => m.default || m)
  },
  {
    name: indexOjY3oAgQCdMeta?.name ?? "ai-review-response-generator",
    path: indexOjY3oAgQCdMeta?.path ?? "/ai-review-response-generator",
    meta: indexOjY3oAgQCdMeta || {},
    alias: indexOjY3oAgQCdMeta?.alias || [],
    redirect: indexOjY3oAgQCdMeta?.redirect,
    component: () => import("/app/pages/ai-review-response-generator/index.vue").then(m => m.default || m)
  },
  {
    name: index3VG1nzc0GsMeta?.name ?? "analytics",
    path: index3VG1nzc0GsMeta?.path ?? "/analytics",
    meta: index3VG1nzc0GsMeta || {},
    alias: index3VG1nzc0GsMeta?.alias || [],
    redirect: index3VG1nzc0GsMeta?.redirect,
    component: () => import("/app/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: indexSIVPhayj0XMeta?.name ?? "autoanswers",
    path: indexSIVPhayj0XMeta?.path ?? "/autoanswers",
    meta: indexSIVPhayj0XMeta || {},
    alias: indexSIVPhayj0XMeta?.alias || [],
    redirect: indexSIVPhayj0XMeta?.redirect,
    component: () => import("/app/pages/autoanswers/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jK3Pyj8dSOMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93jK3Pyj8dSOMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93jK3Pyj8dSOMeta || {},
    alias: _91_46_46_46slug_93jK3Pyj8dSOMeta?.alias || [],
    redirect: _91_46_46_46slug_93jK3Pyj8dSOMeta?.redirect,
    component: () => import("/app/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indextaC1gSU56TMeta?.name ?? "crm",
    path: indextaC1gSU56TMeta?.path ?? "/crm",
    meta: indextaC1gSU56TMeta || {},
    alias: indextaC1gSU56TMeta?.alias || [],
    redirect: indextaC1gSU56TMeta?.redirect,
    component: () => import("/app/pages/crm/index.vue").then(m => m.default || m)
  },
  {
    name: indexthqyUOyVUjMeta?.name ?? "forgot-pass",
    path: indexthqyUOyVUjMeta?.path ?? "/forgot-pass",
    meta: indexthqyUOyVUjMeta || {},
    alias: indexthqyUOyVUjMeta?.alias || [],
    redirect: indexthqyUOyVUjMeta?.redirect,
    component: () => import("/app/pages/forgot-pass/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3gq6Cp6LyXMeta?.name ?? "licensing",
    path: index3gq6Cp6LyXMeta?.path ?? "/licensing",
    meta: index3gq6Cp6LyXMeta || {},
    alias: index3gq6Cp6LyXMeta?.alias || [],
    redirect: index3gq6Cp6LyXMeta?.redirect,
    component: () => import("/app/pages/licensing/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Cy8OQ3llwUMeta?.name ?? "news-slug",
    path: _91_46_46_46slug_93Cy8OQ3llwUMeta?.path ?? "/news/:slug(.*)*",
    meta: _91_46_46_46slug_93Cy8OQ3llwUMeta || {},
    alias: _91_46_46_46slug_93Cy8OQ3llwUMeta?.alias || [],
    redirect: _91_46_46_46slug_93Cy8OQ3llwUMeta?.redirect,
    component: () => import("/app/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexoxV4GRc61hMeta?.name ?? "news",
    path: indexoxV4GRc61hMeta?.path ?? "/news",
    meta: indexoxV4GRc61hMeta || {},
    alias: indexoxV4GRc61hMeta?.alias || [],
    redirect: indexoxV4GRc61hMeta?.redirect,
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexCICQ7TchH0Meta?.name ?? "order",
    path: indexCICQ7TchH0Meta?.path ?? "/order",
    meta: indexCICQ7TchH0Meta || {},
    alias: indexCICQ7TchH0Meta?.alias || [],
    redirect: indexCICQ7TchH0Meta?.redirect,
    component: () => import("/app/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexNwMsGxInsLMeta?.name ?? "prices",
    path: indexNwMsGxInsLMeta?.path ?? "/prices",
    meta: indexNwMsGxInsLMeta || {},
    alias: indexNwMsGxInsLMeta?.alias || [],
    redirect: indexNwMsGxInsLMeta?.redirect,
    component: () => import("/app/pages/prices/index.vue").then(m => m.default || m)
  },
  {
    name: indextCDe67vQCCMeta?.name ?? "privacy",
    path: indextCDe67vQCCMeta?.path ?? "/privacy",
    meta: indextCDe67vQCCMeta || {},
    alias: indextCDe67vQCCMeta?.alias || [],
    redirect: indextCDe67vQCCMeta?.redirect,
    component: () => import("/app/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexKgLIvXGBWxMeta?.name ?? "signin",
    path: indexKgLIvXGBWxMeta?.path ?? "/signin",
    meta: indexKgLIvXGBWxMeta || {},
    alias: indexKgLIvXGBWxMeta?.alias || [],
    redirect: indexKgLIvXGBWxMeta?.redirect,
    component: () => import("/app/pages/signin/index.vue").then(m => m.default || m)
  },
  {
    name: indexxLsScIFze3Meta?.name ?? "signup",
    path: indexxLsScIFze3Meta?.path ?? "/signup",
    meta: indexxLsScIFze3Meta || {},
    alias: indexxLsScIFze3Meta?.alias || [],
    redirect: indexxLsScIFze3Meta?.redirect,
    component: () => import("/app/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexF5mW9etUSbMeta?.name ?? "telegram-bot",
    path: indexF5mW9etUSbMeta?.path ?? "/telegram-bot",
    meta: indexF5mW9etUSbMeta || {},
    alias: indexF5mW9etUSbMeta?.alias || [],
    redirect: indexF5mW9etUSbMeta?.redirect,
    component: () => import("/app/pages/telegram-bot/index.vue").then(m => m.default || m)
  }
]